@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 80%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Some resets */
body {
  margin: 0px;
  color: rgb(32, 33, 36);
  display: block;
  font-family: roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  box-sizing: border-box;
  background: #f9f9fb;
}

p,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.showcase__portfolio {
  color: #fff;
  padding: 8px;
  font-weight: 500;
  font-size: 17px;
  line-height: 46px;
  outline: none !important;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  background: linear-gradient(to right, #4458dc 0%, #854fee 100%),
    radial-gradient(circle at top left, #4458dc, #854fee);
}

.showcase__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.showcase__content__img {
  max-width: 700px;
  max-height: 700px;
}

.showcase__content__img img {
  width: 700px;
  min-height: auto;
}

.showcase__content__infos {
  max-width: 500px;
  position: relative;
}

.separator {
  background-color: rgb(105, 49, 225);
  box-sizing: border-box;
  color: rgb(42, 42, 51);
  font-size: 15px;
  height: 2px;
  line-height: 22.5px;
  text-align: center;
  width: 140px;
  margin: 50px auto;
}

.showcase__cards {
  width: 100%;
  display: flex;
  justify-content: center;
}

.showcase__cards__container {
  display: grid;
  grid-column-gap: 30px;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
}

.showcase__cards__card {
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(42, 42, 51);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-width: 0px;
  border-image-slice: 100%;
  border-image-width: 1;
  border-left-color: rgb(42, 42, 51);
  border-right-color: rgb(42, 42, 51);
  border-right-width: 0px;
  border-top-color: rgb(42, 42, 51);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-width: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
  box-sizing: border-box;
  color: rgb(42, 42, 51);
  font-size: 15px;
  line-height: 22.5px;
  max-width: 100%;
  text-align: center;
  transform: none;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  -moz-box-flex: 2;
  height: 350px;
  margin-bottom: 90px;
  position: relative;
}

.showcase__cards__card i {
  background: linear-gradient(to right, #4458dc 0%, #854fee 100%),
    radial-gradient(circle at top left, #4458dc, #854fee);
  border-radius: 50%;
  padding: 5px;
  color: #fff;
}

.title {
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 3px;
}

.showcase__content__infos h5 {
  font-size: 19px;
  margin-bottom: 7px;
}

.showcase__content__infos p {
  margin-bottom: 7px;
}

.showcase__cards__card:hover {
  transform: scale(1.02) translate(0px, -8px);
  transition: all 300ms ease-in-out 0s;
}

.bracket {
  font-size: 270px;
  position: absolute;
  top: 120px;
  left: -15px;
  color: #8a2be2;
  display: none;
}

.showcase__cards__card__tags {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card__tags__content {
  display: flex;
  flex-direction: column;
}

.card__tags__content p {
  background-color: #854fee;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  margin: 8px 0;
  padding: 8px;
}

.showcase__cards__card__tags {
  display: flex;
}

.logo {
  position: absolute;
}
.logo img {
  width: 100px;
  height: auto;
}

/* 
@media only screen and (max-width: 1300px) {
  .showcase__cards__container {
    grid-template-columns: repeat(3, minmax(300px, 500px));
  }
} */

@media only screen and (max-width: 1250px) {
  .showcase__content {
    flex-direction: column;
    height: auto;
  }
  .showcase__content__img {
    margin-top: 30px;
  }
  .showcase__content__infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .showcase__cards__container {
    grid-template-columns: repeat(3, minmax(200px, 300px));
  }
  .icons {
    position: relative !important;
    margin: 55px 0 !important;
    top: 0;
    left: 0;
    justify-content: center;
  }
  .title {
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .showcase__content__img {
    max-width: 350px;
    max-height: 350px;
  }

  .showcase__content__img img {
    max-width: 350px;
    max-height: 350px;
  }
  .showcase__cards__container {
    grid-template-columns: 300px;
  }
  .icons {
    position: relative !important;
    margin: 55px 0 !important;
    top: 0;
    left: 0;
    justify-content: center;
  }
}

@media only screen and (max-width: 578px) {
  .dropdown {
    top: 175px !important;
  }
}

@media only screen and (max-width: 430px) {
  .showcase__content__infos {
    max-width: 300px;
  }
  .icons {
    flex-wrap: wrap;
  }
  .icons a {
    margin: 15px !important;
  }
}

